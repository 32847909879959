import Physician from "store/models/Physician";
import Clients from "store/models/PayorAccount";
import PhysicianModal from "./pages/Details";
import Actions from "modules/actions/physicians-actions";

const actions = new Actions();

export default {
  name: "Physicians",
  components: {
    PhysicianModal,
  },
  data() {
    return {
      isLoading: true,
      dialog: false,
      isDataLoading: false,
      initialDataLoading: false,
      physicians: [],
      filter: "",
      orderBy: {
        name: "orderByLastName",
        npiNumber: "orderByNpiNumber",
        licenseNumber: "orderByLicenseNumber",
        date: "orderByDate",
        status: "orderByValidityStatus",
      },
      changeStatusForm: {
        id: null,
        isVisible: false,
        isActive: false,
        isDisabled: false,
      },
      columns: [
        {
          name: "lastName",
          align: "left",
          text: "Name",
          field: "name",
          sortable: true,
        },
        {
          name: "npiNumber",
          align: "left",
          text: "NPI Number",
          field: "npiNumber",
          sortable: true,
        },
        {
          name: "licenseNumber",
          align: "left",
          text: "Physician License",
          field: "licenseNumber",
        },
        {
          name: "clients",
          align: "center",
          text: "Client",
          field: "clientLabel",
          isPopUp: true,
        },
        {
          name: "created",
          align: "left",
          text: "Creation Date",
          field: "date",
          isDateColumn: true,
          sortable: true,
        },
        {
          name: "status",
          align: "center",
          text: "Status",
          field: "status",
          isStatusColumn: true,
          sortable: true,
        },
        {
          name: "action",
          text: "Action",
          field: "action",
          align: "center",
        },
      ],
      searchFilter: {
        columns: 3,
        model: Physician,
        fields: [
          {
            label: "First Name",
            name: "firstName",
            type: "text",
          },
          {
            label: "NPI Number",
            name: "npiNumber",
            type: "text",
          },
          {
            label: "Client",
            name: "payorAccount",
            type: "lazy-loading",
            keyValue: "payorCode, payorName",
            render: "<payorCode> (<payorName>)",
            searchBy: "payorCode,payorName",
            byId: true,
            modelQueryParams: {
              queryName: "payorAccounts",
              relativeUrl: "/payor-accounts/get-payor-accounts",
              limitData: ["payorName", "payorCode"],
              filter: {
                archive: true,
              },
            },
            singleSelect: true,
            modelFilter: Clients,
          },
          {
            label: "Last Name",
            name: "lastName",
            type: "text",
          },
          {
            label: "License",
            name: "stateMedicalLicense",
            type: "text",
          },
          {
            label: "Creation Date",
            name: "created",
            type: "date",
            asUtc: true,
          },
        ],
      },
      limitData: [
        "firstName",
        "lastName",
        "npi { npiNumber }",
        "stateMedicalLicense { licenseNumber }",
        "payorAccounts{ payorName }",
        "created",
        "archived",
        "totalCount",
        "terminationDate",
        "effectivityDate",
      ],
      totalCount: 0,
    };
  },
  computed: {
    filteredPhysicians() {
      try {
        let data = this.physicians.map((item) => {
          return {
            id: item.id,
            name: `${item.lastName}, ${item.firstName}`,
            npiNumber: item.npi ? item.npi.npiNumber : "",
            licenseNumber: item.stateMedicalLicense
              ? item.stateMedicalLicense.licenseNumber
              : "",
            clientLabel: "View",
            clients:
              item.payorAccounts && item.payorAccounts.length > 0
                ? item.payorAccounts
                : [],
            date: this.$options.filters.changeDateFormat(
              item.created,
              "MM/DD/YYYY hh:mm A"
            ),
            status: !item.archived ? "Active" : "Deactivated",
            isActive: !item.archived,
            popUpData: {
              clients: {
                columns: 2,
                field: "clients",
                type: "object",
                data: [
                  {
                    col: 1,
                    items: [
                      {
                        label: "CLIENT ACCOUNTS",
                        field: "payorName",
                      },
                    ],
                  },
                ],
              },
            },
          };
        });
        return data;
      } catch (e) {
        this.showNotifyMessage({
          message: "Unable to fetch data.",
          type: "danger",
        });
      }
    },
  },
  methods: {
    async fetch() {
      try {
        this.isLoading = true;
        this.resetDataTableOptions();
        await this.$refs.searchFilter.filterSearchModel(10, null);
        this.isLoading = false;
      } catch (e) {
        this.showNotifyMessage({
          message: "Unable to fetch data.",
          type: "danger",
        });
      }
    },
    loading(bool) {
      this.isDataLoading = bool;
    },
    async searchResults(result) {
      if (result.length) {
        this.totalCount = result[0].totalCount;
      } else {
        this.totalCount = 0;
      }
      this.physicians = result;
    },
    addPhysician() {
      this.$router.push("/system-maintenance/clients-physicians/physician/add");
    },
    editPhysician(id) {
      this.$router.push(
        `/system-maintenance/clients-physicians/physician/edit/${id}`
      );
    },
    toggleChangeStatus(id, isActive) {
      this.handleChangeStatusVisibility(true, id, isActive);
    },
    handleChangeStatusVisibility(value, id = null, isActive = false) {
      const { isVisible } = this.changeStatusForm;
      if (isVisible !== value) {
        this.changeStatusForm = {
          ...this.changeStatusForm,
          isVisible: value,
          id,
          isActive,
        };
      }
    },
    async getChangeStatusAction(params) {
      const { isActive } = this.changeStatusForm;
      if (isActive) {
        return await actions.deactivatePhysician(params);
      }
      return await actions.activatePhysician(params);
    },
    async onSubmitChangeStatus() {
      const { isActive, isDisabled, id } = this.changeStatusForm;
      if (!isDisabled) {
        this.changeStatusForm.isDisabled = true;
        const params = {
          id: {
            type: "UUID!",
            value: id,
            unincludeToFields: true,
          },
          limitData: ["success", "errors"],
          shouldNotReturnId: true,
        };
        try {
          const { success, errors } = await this.getChangeStatusAction(params);
          if (!success || errors.length > 0) {
            const errorMesssage =
              errors.length > 0
                ? errors[0]
                : "Problem has occurred while updating Physician status.";
            throw errorMesssage;
          }
          this.showNotifyMessage({
            message: `Physician ${
              isActive ? "deactivated" : "activated"
            }.`,
            type: "success",
          });
          this.changeStatusForm = {
            id: null,
            isVisible: false,
            isActive: false,
            isDisabled: false,
          };
          this.fetch();
        } catch (error) {
          this.showNotifyMessage({
            message: error,
            type: "danger",
          });
          this.changeStatusForm.isDisabled = false;
        }
      }
    },
    authUserHasAccessControl(task) {
      return this.$store.getters.authUserHasAccessControl(task);
    },
    getData(index) {
      return this.physicians[index];
    },
    resetDataTableOptions() {
      this.$refs.dataTable.resetDataTableOptions();
    },
  },
  mounted() {
    this.$nextTick(async () => {
      this.initialDataLoading = true;
      await this.fetch();
      this.initialDataLoading = false;
    });
  },
};
