import PayorAccount from "store/models/PayorAccount";
import Exclusion from "store/models/Exclusion";

import StoreActions from "store/actions";

const storeActions = new StoreActions(PayorAccount);
const exclusionActions = new StoreActions(Exclusion);
export default class PayorAccountActions {
  async getExclusions(variables) {
    return await exclusionActions.query(
      {
        queryName: "exclusions",
        relativeUrl: "/payor-accounts/exclusions",
        ...variables,
      },
      "to load lists of payor account exclusions."
    );
  }

  async searchPayorAccount(params) {
    const { limitData, limit, offset, filter } = params;

    return await storeActions.query(
      {
        queryName: "payorAccounts",
        relativeUrl: "/payor-accounts/get-payor-accounts",
        limitData: limitData || null,
        limit: limit || null,
        offset: offset || null,
        filter: filter || null,
      },
      "To search payor account."
    );
  }
  async create(params) {
    const { queries, variables, collectionQueries } = params;
    return await storeActions.mutation(
      {
        queryName: "createPayorAccount",
        relativeUrl: "/payor-accounts/create-payor-account",
        queries,
        collectionQueries: collectionQueries || null,
      },
      "To create payor account.",
      { ...variables }
    );
  }

  async update(params) {
    const { queries, variables, collectionQueries } = params;
    return await storeActions.mutation(
      {
        queryName: "updatePayorAccountDetails",
        relativeUrl: "/payor-accounts/update-payor-account",
        queries: queries || null,
        collectionQueries:
          collectionQueries && collectionQueries.length
            ? collectionQueries
            : null,
      },
      "To update payor account.",
      { ...variables }
    );
  }

  async get(params) {
    const { limitData, variables } = params;

    return await storeActions.query(
      {
        queryName: "getPayorAccount",
        relativeUrl: "/payor-accounts/get-payor-account",
        limitData: limitData || null,
      },
      "To fetch payor account.",
      { ...variables }
    );
  }

  async activate(variables) {
    const { id, limitData = [], shouldNotReturnId = false } = variables;
    return await storeActions.mutation(
      {
        queryName: "activatePayorAccount",
        relativeUrl: "/payor-accounts/activate",
        limitData,
        shouldNotReturnId,
      },
      "To activate client.",
      { id }
    );
  }

  async deactivate(variables) {
    const { id, limitData = [], shouldNotReturnId = false } = variables;
    return await storeActions.mutation(
      {
        queryName: "deactivatePayorAccount",
        relativeUrl: "/payor-accounts/deactivate",
        limitData,
        shouldNotReturnId,
      },
      "To deactivate client.",
      { id }
    );
  }

  async getClientInvoicing({ limitData, variables }) {
    return await exclusionActions.query(
      {
        queryName: "getVbsClientInvoicing",
        relativeUrl: "/payor-accounts/get-payor-invoices",
        limitData: limitData || [],
      },
      "to load  client Invoicing",
      {
        ...variables,
      }
    );
  }
}
