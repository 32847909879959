import StateMedicalLicense from "store/models/StateMedicalLicense";

import StoreActions from "store/actions";

const storeActions = new StoreActions(StateMedicalLicense);
export default class StateMedicalLicenseActions {
  async create(params) {
    return await storeActions.mutation(
      {
        queryName: "createStateMedicalLicense",
        relativeUrl: "/sml/create-sml",
      },
      "To create state medical license.",
      { ...params }
    );
  }
}
