var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-searchfilter',{ref:"searchFilter",attrs:{"options":_vm.searchFilter.fields,"modelInstance":_vm.searchFilter.model,"columns":_vm.searchFilter.columns,"limitData":_vm.limitData,"hide-deleted":true,"has-archive":true,"limit":10,"isLoading":_vm.isDataLoading,"has-archive-text":"Include Deactivated","relativeUrl":"/physician/all-list","queryName":"physicians"},on:{"searchResults":_vm.searchResults,"resetDataTableOptions":_vm.resetDataTableOptions,"loading":_vm.loading}}),_c('v-custom-datatable',{ref:"dataTable",staticClass:"data-table",attrs:{"initialLoading":_vm.initialDataLoading,"data":_vm.filteredPhysicians,"columns":_vm.columns,"row-key":"id","filter":_vm.filter,"isLoading":_vm.isDataLoading,"noDataLabel":"No Physician(s) found","flatx":"","totalCount":_vm.totalCount,"searchFilter":_vm.$refs.searchFilter,"orderBy":_vm.orderBy,"bordered":"","withAdd":"","hideDelete":true,"hideView":true,"editLabel":"View/Edit","hasChangeStatus":"","accessControl":{
          authUserHasAddAccessControl: true,
          authUserHasViewAccessControl: true,
          authUserHasEditAccessControl: true,
          authUserHasDeleteAccessControl: true,
        }},on:{"onChangeStatus":_vm.toggleChangeStatus,"onAdd":_vm.addPhysician,"onEdit":_vm.editPhysician}})],1)],1),_c('v-change-status',{attrs:{"visible":_vm.changeStatusForm.isVisible,"id":_vm.changeStatusForm.id,"isActive":_vm.changeStatusForm.isActive,"isDisabled":_vm.changeStatusForm.isDisabled,"header":"Change Status","message":"You are about to change the status of this physician.","warning":"Changing the status will update the Termination Date to today's date and the deactivation will only take effect after the termination date.","buttonTexts":{
      cancel: 'Cancel',
      activate: 'Activate',
      deactivate: 'Deactivate',
    }},on:{"close":function($event){return _vm.handleChangeStatusVisibility(false)},"submit":_vm.onSubmitChangeStatus}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }