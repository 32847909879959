import Physician from "store/models/Physician";

import StoreActions from "store/actions";

const storeActions = new StoreActions(Physician);

export default class PhysicianActions {
  async create(params) {
    const { queries, variables, collectionQueries } = params;
    return await storeActions.mutation(
      {
        queryName: "createPhysician",
        relativeUrl: "/physician/create-physician",
        queries: queries || null,
        collectionQueries: collectionQueries || [],
      },
      "To create payor account.",
      { ...variables }
    );
  }
  async getPhysicianList(variables, desciption = "") {
    return await storeActions.query(
      {
        queryName: "physicians",
        relativeUrl: "/physician/all-list",
        ...variables,
      },
      desciption.length ? desciption : "to load physician list."
    );
  }
  async update(variables) {
    const { queries, params, collectionQueries } = variables;

    return await storeActions.mutation(
      {
        relativeUrl: "/physician/update-physician-details",
        queryName: "updatePhysicianDetails",
        queries: queries || null,
        collectionQueries: collectionQueries || null,
      },
      "To update physician.",
      {
        ...params,
      }
    );
  }
  //update referingPhysician
  async update_rp(variables) {
    const { queries, params, collectionQueries } = variables;

    return await storeActions.mutation(
      {
        relativeUrl: "/physician/update-referring-physician-details",
        queryName: "updateReferingPhysicianDetails",
        queries: queries || null,
        collectionQueries: collectionQueries || null,
      },
      "To update physician.",
      {
        ...params,
      }
    );
  }
  async getPhysicianById(variables, desciption = "") {
    return await storeActions.query(
      {
        queryName: "getPhysician",
        relativeUrl: "/physician/get-physician-by-id",
        ...variables,
      },
      desciption.length ? desciption : "to get physician by id.",
      {
        ...variables.params,
      }
    );
  }

  async activate(variables) {
    const { id, limitData = [], shouldNotReturnId = false } = variables;
    return await storeActions.mutation({
      queryName: "activatePhysician",
      relativeUrl: "/physician/activate",
      limitData,
      shouldNotReturnId,
    }, "To activate physician.", { id });
  }

  async deactivate(variables) {
    const { id, limitData = [], shouldNotReturnId = false } = variables;
    return await storeActions.mutation({
      queryName: "deactivatePhysician",
      relativeUrl: "/physician/deactivate",
      limitData,
      shouldNotReturnId,
    }, "To deactivate physician.", { id });
  }
}
